// =============================================================================
//
//  Dynamic Banner Tablet
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
//==============================================================================

@media screen and (min-width: $tablet-width) {
    .dynamic-message-banner {
        .content-wrapper {
            max-width: $dynamic-banner-content-wrapper__max-width--medium;
            padding: $dynamic-banner-content-wrapper__padding--medium;
            font-size: $dynamic-banner-content-wrapper__font-size--medium;
            line-height: $dynamic-banner-content-wrapper__line-height--medium;
            text-align: center;

            p,
            a {
                font-size: $dynamic-banner-content-wrapper__font-size--medium;
                line-height: $dynamic-banner-content-wrapper__line-height--medium;
            }
        }

        .close-banner-icon {
            right: $dynamic-banner-close-banner-icon__position-right--medium;
        }
    }
}
