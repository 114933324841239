// =============================================================================
//
//  Typography Base Tablet
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
//==============================================================================

// -----------------------------------------------------------------------------
//  HTML and Body:
// -----------------------------------------------------------------------------

@media screen and (min-width: $tablet-width) {
    html {
        font-size: 15px;
    }
}
