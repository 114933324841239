// =============================================================================
//
//  Slick Carousel Variables
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
//==============================================================================

$slick-arrow__width: 3rem;
$slick-arrow__height: 3rem;
$slick-arrow__font-size: 1.6rem;

$slick-arrow-before__top: 0.6rem;
$slick-arrow-before__left: 0.5rem;

$slick-dots__padding: $base-padding 0;
$slick-dots__margin: 0;

$slick-dots-li__margin: 0 ($base-margin * 0.25);

$slick-dots-button__width: 1rem;
$slick-dots-button__height: 1rem;
$slick-dots-button__background-color: rgba($black, 0.3);

$slick-dots-li-active__background-color: $black;
