// =============================================================================
//
//  MiniCart
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@import '../../themes/default/components/minicart/miniCart';

.overlay-minicart {
    position: fixed;
    z-index: 8;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    transition: visibility 0.3s, opacity 1s ease;


    &.hide {
        opacity: 0;
        transition: visibility 0.3s, opacity 1s ease;
        visibility: hidden;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.valid-cart-error {
    @include flexbox();
    @include get-font('small');
    position: $valid-cart-error__position;
    padding: $valid-cart-error__padding;
    margin-bottom: $valid-cart-error__margin-bottom;
    background: $valid-cart-error__background;
    color: $valid-cart-error__color;

    .close {
        @include get-font('medium');
        position: $valid-cart-error-close__position;
        top: $valid-cart-error-close__top;
        right: $valid-cart-error-close__right;
        height: $valid-cart-error-close__height;
        padding: 0;
        background: none;
        color: $valid-cart-error-close__color;
        transform: translateY(-$valid-cart-error-close__top);

        .icon-close {
            @include get-font('medium');
        }
    }
}

.cart-box-item {
    @include flexbox();
    @include flex-direction(row);
    margin-bottom: $cart-box-item__margin-bottom;

    &-icon {
        @include get-font('micro');
        @include flexbox();
        @include flex(0 0 2.5rem);
        @include align-items(center);
        @include justify-content(center);
        width: $cart-box-item__width;
        height: $cart-box-item__height;
        border-radius: $cart-box-item__border-radius;
        background: $cart-box-item__background;
        color: $cart-box-item__color;

        .icon-check {
            position: $cart-box-item__position;
            top: $cart-box-item__top;
        }
    }

    &-title {
        @include get-font('small');
        margin-left: $cart-box-item-title__margin-left;
        color: $cart-box-item-title__color;
        line-height: $cart-box-item-title__line-height;

        &-name {
            font-weight: $cart-box-item-title__font-weight;
        }

        &-name-msg {
            margin-left: $cart-box-item-title__line-height__margin-left;
        }

        &:hover {
            $cart-box-item-title__color: $black;
        }
    }
}

.cart-box-items {
    margin-bottom: $cart-box-items__margin-bottom;
}

.cart-box-free-delivery-msg {
    text-align: center;
}

.cart-box-free-item-icon {
    display: inline-block;
    padding-right: $base-padding * 0.5;
    vertical-align: middle;
}

.added-to-cart-box {
    position: $added-to-cart-box__position;
    z-index: $added-to-cart-box__z-index;
    top: $added-to-cart-box__top;
    right: 0;
    display: none;
    width: $added-to-cart-box__width;
    padding: $added-to-cart-box__padding;
    background: $added-to-cart-box__background;
    box-shadow: $added-to-cart-box__box-shadow;

    .msg-bonus-product {
        display: none;
    }

    &-content {
        height: $added-to-cart-box-content__height;
        overflow-y: $added-to-cart-box-content__overflow-y;
    }

    .arrow {
        position: $added-to-cart-box-arrow__position;
        top: $added-to-cart-box-arrow__top;
        right: $added-to-cart-box-arrow__right;
        display: block;
        width: $added-to-cart-box-arrow__width;
        height: $added-to-cart-box-arrow__height;
        margin: $added-to-cart-box-arrow__margin;

        &::before {
            content: '';
            position: $added-to-cart-box-arrow__position;
            top: 0;
            display: block;
            border-width: $added-to-cart-box-arrow--before__border-width;
            border-style: $added-to-cart-box-arrow--before__border-style;
            border-color: $added-to-cart-box-arrow--before__border-color;
            border-bottom-color: $added-to-cart-box-arrow--before__border-bottom-color;

        }

        &::after {
            content: '';
            position: $added-to-cart-box-arrow__position;
            top: $added-to-cart-box-arrow--after__top;
            display: block;
            border-width: $added-to-cart-box-arrow--before__border-width;
            border-style: $added-to-cart-box-arrow--before__border-style;
            border-color: $added-to-cart-box-arrow--before__border-color;
            border-bottom-color: $added-to-cart-box-arrow--after__border-bottom-color;
        }
    }

    .checkout-continue {
        padding-top: $checkout-continue__padding-top;
        border-top: $checkout-continue__border-top;
        margin-top: 0;
    }
}

.empty-cart-box {
    @include get-font($size: 'small');

    position: $added-to-cart-box__position;
    z-index: $added-to-cart-box__z-index;
    top: $added-to-cart-box__top;
    right: 0;
    display: none;
    width: $added-to-cart-box__width;
    padding: $added-to-cart-box__padding;
    background: $added-to-cart-box__background;
    box-shadow: $added-to-cart-box__box-shadow;
    color: $black;

    .arrow {
        position: $added-to-cart-box-arrow__position;
        top: $added-to-cart-box-arrow__top;
        right: -$added-to-cart-box-arrow__top/2;
        display: block;
        width: $added-to-cart-box-arrow__width;
        height: $added-to-cart-box-arrow__height;
        margin: $added-to-cart-box-arrow__margin;

        &::before {
            content: '';
            position: $added-to-cart-box-arrow__position;
            top: 0;
            display: block;
            border-width: $added-to-cart-box-arrow--before__border-width;
            border-style: $added-to-cart-box-arrow--before__border-style;
            border-color: $added-to-cart-box-arrow--before__border-color;
            border-bottom-color: $added-to-cart-box-arrow--before__border-bottom-color;

        }

        &::after {
            content: '';
            position: $added-to-cart-box-arrow__position;
            top: $added-to-cart-box-arrow--after__top;
            display: block;
            border-width: $added-to-cart-box-arrow--before__border-width;
            border-style: $added-to-cart-box-arrow--before__border-style;
            border-color: $added-to-cart-box-arrow--before__border-color;
            border-bottom-color: $added-to-cart-box-arrow--after__border-bottom-color;
        }
    }
}

.minicart {
    position: relative;

    &-quantity {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        @include get-font('micro');
        position: $minicart-quantity__positon;
        top: $minicart-quantity__top;
        left: $minicart-quantity__left;
        width: $minicart-quantity__width;
        height: $minicart-quantity__height;
        border-radius: $minicart-quantity__border-radius;
        background-color: $minicart-quantity__background-color;
        color: $minicart-quantity__color;
        font-family: $teko;
        font-weight: $bold;
        line-height: $minicart-quantity__line-height;

        &.empty-cart {
            display: none;
        }
    }

    &-wrapper {
        position: $minicart-wrapper__position;
        z-index: 9;
        top: $minicart-wrapper__top;
        right: $minicart-wrapper__right;
        bottom: $minicart-wrapper__bottom;
        width: $minicart-wrapper__width;
        max-width: $minicart-wrapper__max-width;
        background: $minicart-wrapper__background;
        box-shadow: $minicart-wrapper__box-shadow;
        color: $minicart-wrapper__color;
        cursor: default;
        transition: all 0.2s ease;

        &.show {
            right: 0;
            transition: all 0.2s ease;
        }

        .cart,
        .cart-widget {
            @include flexbox();
            @include flex-flow(column);
            height: 100%;
        }
    }

    &-products {
        @include flex-grow(1);
        padding: $minicart-products__padding;
        border-top: $minicart-products__border-top;
        margin-top: $minicart-products__margin-top;

        .quantity-form {
            margin-top: $minicart-quantity-form__margin-top;

            &.gc-line-item {
                .quantity-icon {
                    visibility: hidden;
                }
            }
        }

        .quantity-form .quantity {
            @include get-font('xsmall');
            width: $minicart-quantity-quantity-form-quantity__width;
            height: $minicart-quantity-quantity-form-quantity__height;
            padding: 0;
            margin: $minicart-quantity-quantity-form-quantity__margin;
            cursor: initial;

            &:focus {
                box-shadow: none;
            }
        }

        .quantity-form .quantity-icon {
            @include get-font('micro');
            padding: 0;
        }
    }

    &-content {
        height: 100%;
        overflow-y: auto;

        .product {
            border: none;
        }

        &-header {
            padding: $minicart-content-header__padding;
        }

        &-title {
            @include flexbox();
            @include align-items(center);
            @include get-font('large');
            color: $minicart-content-title__color;
            font-weight: $minicart-content-title__font-weight;
        }
    }

    &-footer {
        position: relative;
        width: 100%;
        padding: $minicart-footer__padding;
        background: $minicart-footer__background;

        &-bottom {
            @include get-font('mini-xsmall');
            @include flexbox();
            @include align-items(center);
            padding-top: $minicart-footer-bottom__padding-top;

            .icon-footer {
                @include get-font('compact');
                margin-right: $icon-footer__margin-right;
                color: $icon-footer__color;
                vertical-align: $icon-footer__vertical-align;
            }
        }

        &-img {
            @include flexbox();
            @include align-items(center);
            padding-right: $minicart-footer-img__padding-right;
            border-right: $minicart-footer-img__border-right;
            margin-right: $minicart-footer-img__margin-right;

            .footer-cards-img {
                height: 1.5rem;
                margin-right: $footer-cards-img__margin-right;
            }
        }

        &-return {
            @include flexbox();
            @include align-items(center);
        }
    }

    .product-tile {
        padding: $minicart-product-tile__padding;
        border-top: none;
        border-bottom: $minicart-product-tile__border-bottom;

        &-remove {
            @include get-font('small');
            @include flexbox();
            @include align-items(center);
            width: $minicart-product-tile-remove__width;
            height: $minicart-product-tile-remove__height;
            margin-right: $minicart-product-tile-remove__margin-right;
        }

        &-image-wrapper {
            width: $minicart-product-tile-image-wrapper__width;
            
            .product-tile-image {
                width: $minicart-product-tile-image__width;
                min-width: auto;
                height: $minicart-product-tile-image__height;

                &__container {
                    width: $minicart-product-tile-image__width;
                    height: $minicart-product-tile-image__height;
                }
            }
        }

        &.bonus-tile-product {
            padding-top: $minicart-bonus-product-tile__padding-top;
        }
    }

    &-total-saving {
        @include flexbox();
        @include align-items(center);
        @include get-font('xsmall');
        margin-bottom: $minicart-total-saving__margin-bottom;
        font-family: $montserrat;

        &-amount {
            @include get-font('medium-big');
            margin-left: auto;
            color: $minicart-total-saving-amount__color;
        }

        &-product-amount-price {
            font-family: $teko;
            font-weight: $bold;
        }
    }

    &-messages {
        padding: $minicart-messages__padding;
    }

    .product-card-is-eligible-for-pledge {
        @include get-font('mini');
        color: $greyish-brown-two;
    }
}

.close-minicart {
    @include get-font('almost-big');
    margin-left: auto;
    cursor: pointer;
}

.cart-delete-confirmation-btn {
    padding: 0;
    margin: 0;
    background: none;
    color: $cart-delete-confirmation-btn__color;

    &:hover,
    &:focus {
        background: none;
        color: $cart-delete-confirmation-btn__color;
    }
}



.sub-total-label {
    @include flexbox();
    @include align-items(center);
    @include get-font('xsmall');
    margin-bottom: $sub-total-label__margin-bottom;
    color: $sub-total-label__color;
}

.sub-total {
    @include get-font('large-xlarge');
    margin-left: auto;
    color: $sub-total__color;
    font-family: $teko;
    font-weight: $sub-total__font-weight;
    text-align: $sub-total-label__text-align;
}

.grand-total-sum {
    font-family: $teko;
    font-weight: $grand-total__font-weight;
}

.checkout-btn {
    @include get-font('medium');
    display: $checkout-btn__display;
    width: $checkout-btn__width;
    padding: $checkout-btn__padding;
    background: $checkout-btn__background;
    color: $checkout-btn__color;
    font-weight: $checkout-btn__font-weight;
    text-align: $checkout-btn__text-align;
    text-decoration: none;

    &:hover {
        background: $checkout-btn-hover__background;
        color: $checkout-btn__color;
    }
}

.minicart-product-tile-price {
    @include get-font('xsmall-small');
    @include flex-flow(row);
    @include align-items(flex-end);
    height: 100%;
    margin-top: $minicart-product-tile-price__margin-top;

    .price {
        .price-non-adjusted {
            @include get-font('xsmall');
            font-family: $teko;
            text-align: left;
        }

        .price-adjusted,
        .price-sales {
            @include get-font('medium-big');
            font-family: $teko;
            font-weight: $bold;
        }

        .price-sales {
            color: $minicart-product-tile-price-sales__color;
        }
    }
}

.line-item-pricing-info {
    @include get-font('xsmall');
    padding-top: 2px;
    color: $line-item-pricing-info__color;

    &.hide-for-minicart {
        display: none !important; /* overwrite class from checkout template */
    }
}

.line-item-total-price-amount {
    @include get-font('medium-big');
    margin-left: $line-item-total-price-amount__margin-left;
    color: $line-item-total-price-amount__color;
    font-family: $teko;
    font-weight: $line-item-total-price-amount__font-weight;
}

.line-item-total-price {
    &.have-discount {
        height: $line-item-total-price-have-discount__height;
    }
}

.line-item-unit-price {
    @include flexbox();
    @include align-items(center);
}

.line-item-total-price {
    @include flexbox();
    @include align-items(baseline);
}

.line-item-name {
    @include get-font('small');
}

.line-item-link {
    text-decoration: none;
}

.line-item-brand,
.line-item-included-products {
    @include get-font('xsmall');
    color: $line-item-brand__color;
    font-weight: normal;
}

.bonus-tile {
    @include get-font('mini');
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    position: $bonus-tile__position;
    top: $bonus-tile__top;
    right: $bonus-tile__right;
    width: $bonus-tile__width;
    height: $bonus-tile__height;
    background: $bonus-tile__background;
    color: $bonus-tile__color;
    font-weight: $bolder;
}

.minicart-message {
    @include get-font('small');
    padding: $base-padding * 1.2 $base-padding * 1;
    margin-bottom: $base-margin * 1;
    color: $black;

    &.bonus-banner-hidden {
        display: none;
    }
}

.msg-error {
    background: $msg-error__background;
    color: $msg-error__color;
}

.msg-bonus-product {
    background: $msg-bonus-product__background;

    > .link {
        @include get-font('small');
    }
}

.msg-free-delivery {
    background: $msg-free-delivery__background;
}

.qty-card-quantity-label,
.qty-card-quantity-count {
    @include get-font('xsmall');
    color: $brown-grey-three;
}

