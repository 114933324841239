// =============================================================================
//
//  All Brands Page
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================

@import '../../themes/default/components/allbrands/allbrands';

.all-brands {
    margin: $all-brands__margin;

    &-title {
        margin-bottom: $all-brands-title__margin-bottom;
        text-align: center;
    }

    &-letter-list {
        margin: $all-brands-letter-list__margin;
        text-align: center;

        &-link {
            @include get-font('normal');
            padding: $all-brands-letter-list-link__padding;
            text-decoration: none;
        }
    } 

    &-box {
        border-top: $all-brands-box__border-top;

        &-item {
            @include flexbox();
            @include flex-flow(row wrap);
            padding: $all-brands-box__border-top__padding;
            border-bottom: $all-brands-box__border-top__border-bottom;
        }

        &-letter {
            @include flexbox();
            @include get-font('small');
            @include flex(0 0 100%);
            @include align-items(center);
            @include justify-content(flex-start);
            margin-bottom: $all-brands-box-letter__margin-bottom;
            font-weight: $all-brands-box-letter__font-weight;
            text-align: center;
        }

        &-list {
            @include flex(0 0 100%);
            @include flexbox();
            @include flex-flow(row wrap);

            &-li {
                padding: 0;
                text-decoration: none;
            }

            &-link {
                @include get-font('xsmall');
                display: block;
                text-decoration: none;
            }

            &-item {
                @include flex(0 0 100%);
                padding: $all-brands-box-list-item__padding;
            }
        }
    }
}

@import '../../screens/large/components/allbrands/allbrands';
