// =============================================================================
//
//  Quick View
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@import '../themes/default/components/quickView';
@import '../product/detail';

// Modal General Style

.modal {
    &-open {
        overflow: hidden;
    }

    &-backdrop {
        position: fixed;
        z-index: $quickview-modal-backdrop__z-index;
        top: 0;
        left: 0;
        width: $quickview-modal-full-viewport_width;
        height: $quickview-modal-full-viewport_height;
        background-color: $quickview-modal-backdrop__background-color;

        &.fade {
            opacity: 0;
        }

        &.show {
            opacity: $quickview-modal-backdrop__opacity;
        }
    }
}

// Quickview Modal Style

.quickview-modal {
    position: fixed;
    z-index: $quickview-modal__z-index;
    top: 0;
    left: 0;
    display: none;
    width: $quickview-modal-full_width;
    height: $quickview-modal-full_height;
    outline: 0;
    overflow-y: scroll;

    .quickview-modal-dialog {
        position: relative;
        width: auto;
        max-width: $quickview-modal-dialog__max-width;
        margin: $quickview-modal-dialog__margin;
        pointer-events: none;
    }

    &-content {
        @include flexbox();
        @include flex-direction(column);
        position: relative;
        width: $quickview-modal-full_width;
        background-clip: padding-box;
        background-color: $quickview-modal-content_background-color;
        outline: 0;
        pointer-events: auto;
    }

    &-header {
        @include flexbox();
        @include justify-content(flex-end);
        padding: $quickview-modal-header__padding;

        &.modal-header {
            @include flexbox();
            @include justify-content(flex-end);
            padding: $quickview-modal-header__padding;
        }
    }

    &-title {
        margin-bottom: 0;
    }

    &-body {
        @include flex(1 1 auto);
        position: relative;
        padding: $quickview-modal-inner__padding;
    }

    &-close-button {
        padding: 0;
        background-color: $quickview-close-button__background-color;
        color: $quickview-close-button__color;
        font-size: $quickview-close-button__font-size;

        &:hover {
            background-color: $quickview-close-button__background-color;
            color: $quickview-close-button__color;
        }
    }

    &-product-name {
        max-width: 95%;
        color: inherit;
        text-decoration: none;

        &:hover {
            color: inherit;
        }
    }

    .wishlist-wrapper {
        position: absolute;
        top: 3.5rem;
        right: 3rem;
    }

    + .modal-backdrop {
        display: block;
    }

    .cart-and-ipay .notify-me {
        @include get-font('compact');
    }
}
