// =============================================================================
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
// =============================================================================

@import '../themes/default/components/footer';

.newsletter-description {
    .promotions {
        .promotion {
            background: $black;
            &::after {
                display: none;
            }
        }
    }
}

.newsletter-container {
    background-color: $newsletter__background-color;

    .newsletter-message {
        @include get-font('xsmall');
        padding: $newsletter-message__padding;
        background-color: $newsletter__background-color;
    }

    .newsletter-success-message {
        background-color: $newsletter__background-color;
        color: $newsletter-success-message__color;
        
    }
    
    .newsletter-error-message {
        background-color: $newsletter__background-color;
        color: $newsletter-error-message__color;
    }

    .newsletter {
        padding-top: $newsletter__padding;
        padding-bottom: $newsletter__padding-bottom;

        &-bottom {
            @include flexbox;
            @include flex-wrap(wrap);
        }

        &-description {
            @include flexbox;
            @include align-items(center);
            margin-bottom: $newsletter-description-text__margin-top;

            .promotions {
                position: relative;
            }
        }

        &-description-icon {
            height: $newsletter-description-icon__height;
            color: $newsletter-description-icon__color;
            font-size: $newsletter-description-icon__font-size;
        }

        &-description-title {
            @include get-font('compact');
            color: $newsletter-description-title__color;
            font-weight: $newsletter-description-title__font-weight;
            margin-left: 1rem;

            .newsletter-description-title-mobile-hidden {
                display: none;
            }
        }

        &-description-text {
            @include get-font('small');
            color: $newsletter-description-title__color;
        }

        &-form {
            @include flexbox;
            @include flex-flow(column);
            width: 100%;
            margin-top: $newsletter-input-container__margin-top;

            &-input-container {
                p {
                    @include get-font('xsmall');
                    color: $white;
                    margin-top: 1.5rem;
                }

                a {
                    @include get-font('xsmall');
                    color: $white;
                    text-decoration-color: $white;
                }
            }

            &-input {
                height: $newsletter-input-container__height;
                padding: $newsletter-input__padding;
                background-color: $newsletter-input-container__background-color;
            }

            &-subscribe {
                @include flex(1 1 30%);
                min-width: $newsletter-form-subscribe__min-width;
                padding: 1.5rem;
                margin-top: 1.8rem;
                background: $black;

                &:hover {
                    background: $orange-dash;
                }
            }
        }
    }
}

.footer-wrapper-content {
    &-li {
        @include get-font('xsmall');
        line-height: $footer-wrapper-content__line-height;
    }

    &-link {
        color: $footer-wrapper-content-link__color;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            color: $footer-wrapper-content-link-active__color;
        }
    }
}

.footer-wrapper-collapsible {
    .footer-wrapper-title {
        .title {
            color: $footer-wrapper-title__color;
            cursor: pointer;
            font-weight: $footer-wrapper-title__font-weight;
        }
    }

    &.active {
        .footer-wrapper-title {
            .title {
                font-weight: $footer-wrapper-title__font-weight;
            }
        }
    }
}

footer {
    .footer-flags-img,
    .footer-social-media-img,
    .footer-cards-img,
    .footer-logo-img {
        position: static;
        width: auto;
        height: auto;
        object-position: initial;
    }

    .footer-logo-img {
        height: $footer-logo-img__max-height;
    }


    .footer-wrapper {
        @include flex-flow(row wrap);
        @include flexbox;
        padding: $footer-wrapper__padding;
        border: none;
        border-bottom: $footer-wrapper__border-bottom;

        &__padd-bot-0 {
            padding-top: 0;
            padding-bottom: 0;
        }

        .footer-wrapper-section {
            width: 100%;
        }

        &.no-padd {
            padding: 0;
        }

        &-collapsible {
            @include flex(1 1 100%);
            border: none;
            border-bottom: $footer-wrapper-collapsible__border-bottom;

            &:last-of-type {
                border-bottom: none;
            }

        }

        &.footer-cards {
            @include justify-content(center);
            @include flexbox;
            @include flex-flow(row wrap);
            @include align-items(center);
        }

        .footer-cards-img {
            position: static;
            max-height: $footer-cards-img__max-height;
            vertical-align: middle;

            &.klarna {
                height: $footer-cards-img-klarna__max-height;
                max-height: $footer-cards-img-klarna__max-height;
            }
        }

        .footer-cards-link {
            margin: $footer-cards-link__margin;

            &:last-of-type {
                margin-right: 0;
            }
        }

        &__flags {
            @include flexbox;
            @include align-items(baseline);
            padding-bottom: 0;
            border-bottom: none;

            .footer-copyright {
                @include get-font('tiny');
                @include flex(0 0 100%);
                @include flexbox;
                @include justify-content(flex-start);
                padding-top: $footer-copyright__padding-top;
                color: $footer-copyright__color;
            }
        }
    }

}

.footer-social-media {
    @include flexbox;
    @include flex-wrap(wrap);
    width: 100%;

    .footer-social-media__box {
        @include flex(1 1 15rem);
        @include flexbox;
        @include justify-content(flex-end);
        @include align-items(center);

        &.footer-social-media__box-logo {
            @include justify-content(flex-start);
            @include flex(0 0 40%);

            .footer-logo {
                width: $footer-logo__width;
                height: $footer-logo__height;
            }
        }

        &-span {
            @include get-font('xsmall');
            display: inline-block;
            margin-right: $footer-social-media-box-span__margin-right;
            color: $footer-social-media-box-span__color;
            line-height: $footer-social-media-box-span__line-height;
        }

        .footer-social-media-link {
            display: inline-block;
            height: $footer-social-media-link__height;
            margin-right: $footer-social-media-link-facebook__margin-right;
            color: $footer-social-media-link__color;
            font-size: $footer-social-media-link__font-size;
            text-decoration: none;
        }
    }
}

.footer-wrapper__flags {
    .footer-flags {
        @include flex(0 0 100%);
        border-bottom: $footer-flags__border-bottom;

        &-link {
            display: inline-block;
            padding-bottom: $footer-flags-link__padding-botom;
            margin-right: $footer-flags-link__margin-right;

            &.active {
                border-bottom: $footer-flags-link-active__border-bottom;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        &-img {
            height: $footer-flags-img__height;
        }
    }
}

.footer {
    padding-top: $footer__padding;
    padding-bottom: $footer__padding;
    background-color: $footer__background-color;
    color: $footer__color;
}

