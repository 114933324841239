// =============================================================================
//
//  Detail
//
//  @version    0.0.1
//  @package    HSNG -> Protein Fabrikken
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/modal';


@import '../settings';
@import '../typography/mixins';
@import '../mixins/flex';

@import '../themes/default/product/detail';
@import './bonusProductModal';

.go-back {
    display: none;
}

.view-full-description {
    @include get-font('small');
    display: block;
    margin-top: $base-margin * 1;
    color: $greyish-brown-two;
    font-family: $montserrat;
    text-decoration-color: $very-light-pink;
}

.breadcrumb {
    margin-top: $breadcrumb__margin-top;

    &-item {
        @include get-font('mini');
        color: $breadcrumb-item__color;
        font-family: $montserrat;
        text-decoration: none;
    }
}

.product-detail {
    .breadcrumb {
        margin-top: $base-margin * 2;
    }

    .hr-line-one {
        border: 0;
        border-top: $hr-line-one__border-top;
    }

    .hr-line-two {
        display: none;
    }

    .hr-line-four {
        border: 0;
        border-top: $hr-line-four__border-top;
    }
}

.product-name {
    @include flexbox();
    @include justify-content(space-between);
    margin-top: $product-name__margin-top;

    .wishlist-wrapper {
        position: relative;
        right: auto;
        bottom: auto;
        margin-top: $product-wishlist-wrapper__margin-top;
        float: right;
    }
}

.brand-link {
    display: block;
    margin: $brand-link__margin;
    color: $brand-link__color;
    font-family: $montserrat;
    text-decoration-color: $brand-link__text-decoration-color;
}

.product-swatch-images {
    display: none;
}

.product-images {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-top: $product-images__margin-top;
    margin-bottom: $product-images__margin-bottom;

    .promotion-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;

        .promotion {
            padding: 0.8rem 1rem !important;

            &.custom {
                &::after {
                    border-top-color: $product-images-promotions-right-after__border-top-color;
                }
            }

            &.new {
                &::after {
                    border-top-color: $product-images-promotions-new-right-after__border-top-color;
                }
            }
        }
    }

    &.mobile {
        position: relative;
        z-index: 0;
        opacity: 1;
        visibility: visible;

        .slick-dots {
            @include justify-content(center);
            top: 100%;
            right: auto;
            width: $product-image-slick-dots__width;
            padding: $product-image-slick-dots__padding;
        }

        &.portrait-view {
            .slick-item {
                @include align-items(stretch);
                @include flex-direction(column);
                @include flex-shrink(0);
                position: relative;
                display: block;
                overflow: hidden;
                padding: 30% 0 100%;
            }

            .product-image {
                position: absolute;
                top: 50%;
                right: 0;
                left: 50%;
                width: 130%;
                height: auto;
                margin: 0 auto;
                object-fit: cover;
                text-align: center;
                transform: translate(-50%, -50%);
                user-select: none;
            }

            .slick-dots {
                @include justify-content(center);
                width: 100%;
                margin: 0;
            }
        }
    }

    &.desktop {
        position: absolute;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
    }

    .product-image {
        width: $product-image__width;
        margin: 0 auto;
    }

    .promotions {
        position: $promotions__position;
        z-index: $promotions__z-index;
        top: $promotions__top;
    }

    .promotion {
        @include get-font('tiny');
        position: relative;
        min-width: $promotions-promotion__min-width;
        padding: $product-images-promotions-promotion__padding;
        background-color: $promotions-promotion__background-color;
        color: $promotions-promotion__color;

        &.new {
            background-color: $promotions-promotion-new__background-color;
            color: $promotions-promotion-new__color;
        }

        &.sale {
            background-color: $promotions-promotion-sale__background-color;
            color: $promotions-promotion-sale__color;
        }

        &.badge--priceworthy {
            background: $black;
            color: $white;
        }

        > span {
            margin-top: $promotions-promotion-span__margin-top;
            text-align: center;
        }
    }
}

.product-rating {
    margin-top: $product-rating__margin-top;
    margin-bottom: $product-rating__margin-bottom;
    white-space: nowrap;

    .gmf-product-rating {
        @include flexbox();
        @include justify-content(flex-end);
        @include align-items (center);
        @include flex-wrap(nowrap);
        @include flex-direction (row-reverse);
    }

    .gmf-rating {
        height: 2.3rem;
        order: 1;
    }

    .gmf-product-rating-label {
        @include get-font('xsmall');
        order: 0;
        margin-right: $base-margin;
        color: $greyish-brown-two;
    }
}

.price-runner-badge {
    margin-right: auto;
    margin-left: $base-margin * 1.5;
}

.prices-and-availability {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(baseline);
    @include flex-wrap(nowrap);
    position: relative;
    margin-top: $prices-and-availability__margin-top;

    .prices {
        @include flexbox;
        @include align-items(flex-start);
        @include flex-direction(column);
        width: 100%;
    }

    .price {
        @include flexbox;
        @include flex-direction(column);
        @include align-items(baseline);
        width: 100%;
        font-family: $teko;

        .price-adjusted {
            @include get-font('xlarge');
            color: $prices-price-adjusted__color;
            font-family: $teko;
            font-weight: $prices-price-adjusted__font-weight;
        }

        .price-non-adjusted {
            @include get-font('small');
            color: $prices-price-non-adjusted__color;
            font-family: $teko;
            text-decoration: line-through;
        }

        .price-sales {
            @include get-font('xlarge');
            font-weight: $prices-price-sales__font-weight;
        }

        .price-ref {
            @include get-font('mini');
            width: 100%;
            font-family: $montserrat;
        }
    }
}

// Reference price block.
.price-ref {
    .price-ref__stmt-container {
        @include flexbox;
        @include flex-flow(row nowrap);
        justify-content: space-between;

        .price__discount {
            &--percentage {
                color: $prices-price-ref-price-discount__color;
                font-weight: $bold;
            }
        }
    }

    .price-ref__stmt:nth-child(2) {
        padding-left: 1rem; // Minimum padding in case of too tight distance from flex.
    }
}

.product-set-disclaimer {
    order: 3;
}

.product-availability {
    position: absolute;
    top: $product-availability-msg-item__top;
    right: 0;
    white-space: nowrap;

    .availability-msg {
        .item {
            @include get-font('xsmall-small');

            &::before {
                content: '';
                display: inline-block;
                width: $product-availability-msg-item-before__width;
                height: $product-availability-msg-item-before__height;
                border-radius: 50%;
                margin-right: $product-availability-msg-item-before__margin-right;
            }

            &.in-stock {
                color: $grey;
                // stylelint-disable selector-max-compound-selectors
                i {
                    color: $blue;
                    vertical-align: middle;
                }

                &::before {
                    display: none;
                    background-color: $blue;
                }
            }

            &.low-stock {
                color: $product-availability-msg-item-low-stock__color;

                &::before {
                    background-color: $product-availability-msg-item-low-stock-before__background-color;
                }
            }

            &.out-of-stock {
                color: $product-availability-msg-item-out-of-stock__color;

                &::before {
                    background-color: $product-availability-msg-item-out-of-stock-before__background-color;
                }
            }
        }
    }
}

.product-is-eligible-for-pledge-wrapper {
    margin-top: $base-margin;
}

.product-is-eligible-for-pledge {
    @include get-font('mini');
    color: $greyish-brown-two;
    font-family: $montserrat;
}

.price-per-kg {
    margin: $price-per-kg__margin;
}

.price-per-kg-value {
    @include get-font('small');
}

.product-variation-info {
    display: none;
}

.quantity-notification-message {
    order: 9;
    display: block;
    @include get-font('medium-big');
    color: $notification-red;
    margin-top: $base-margin;
}

.hide {
    display: none;
}

.variation-dropdown,
.set-product-dropdown {
    margin-top: $variation-dropdown__margin-top;

    .variations {
        padding: 0;

        > .variation-item,
        > .set-product-item {
            padding: $variation-dropdown-variation-item__padding;
            cursor: pointer;

            &:hover {
                background: rgba($pf-grey, 0.3);
            }
        }

        .variation-span {
            @include get-font('xsmall');

            &.unselectable {
                color: $variation-dropdown-variation-span-unselectable__color;
            }
        }

        .orange {
            color: $variation-dropdown-variations__color;
        }
    }

    .no-inventory {
        &.hide {
            display: none;
        }
    }

    &-rectangle {
        &.greyed-out {
            background-color: $variation-dropdown-rectangle__background-color;
        }
    }
}

.product-variations {
    .product-variation-info {
        display: none;
    }

    .dropdown__container {
        .title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .dropdown-content {
            overflow: auto;
            max-height: $variation-dropdown__max-height;
        }
    }
}

.quantity-and-add-to-cart {
    @include flexbox;
    @include flex-flow(row nowrap);
    @include align-items(center);
    margin-top: $quantity-and-add-to-cart__margin-top;

    .quantity-form {
        margin-right: $quantity__margin-right;
    }

    .cart-and-ipay {
        @include flex-grow(1);

        .add-to-cart,
        .notify-me {
            width: 100%;
            padding: $cart-and-ipay-add-to-cart__padding;

            &.hide {
                display: none;
            }

            &:disabled {
                background-color: $brown-grey;
            }
        }
    }

    .product-buy-buttons-wrapper {
        @include flex-grow(1);
    }
}

.product-klarna-placement {
    min-height: 6rem;
    margin-top: $base-margin * 1.7;
}

.product-outofstock-popup__container {
    position: relative;

    &.hide {
        display: none;
    }

    .product-outofstock-popup {
        position: absolute;
        z-index: 1;
        top: $product-outofstock-popup__top;
        border: $product-outofstock-popup__border;
        background-color: $product-outofsstock-popup__background-color;
        box-shadow: $product-outofsstock-popup__box-shadow;

        &-cancel {
            @include get-font('small');
            text-decoration-color: rgba($black-two, 0.2);
        }

        &-close-link {
            @include get-font('small');
            text-decoration-color: rgba($black-two, 0.2);
        }

        &-wrapper {
            padding: $product-outofsstock-popup-wrapper__padding;
        }

        &-close {
            position: absolute;
            right: $product-outofsstock-popup-close__right;
            cursor: pointer;
            font-size: $product-outofsstock-popup-close__font-size;
        }

        &-question {
            @include get-font('compact');
            margin-right: $product-outofsstock-popup-question__margin-right;
            font-weight: $bolder;
        }

        &-email-form {
            margin-top: $product-outofsstock-popup-email-form__margin-top;
            margin-bottom: $product-outofsstock-popup-email-form__margin-bottom;

            &-label {
                @include get-font('tiny');
                display: none;
            }
        }

        &-submit {
            width: 100%;
            padding-top: $product-outofstock-popup-submit__padding-top;
            padding-bottom: $product-outofstock-popup-submit__padding-bottom;
            background: $product-outofstock-popup-submit__background;
            color: $product-outofstock-popup-submit__color;
        }

        &-email-submit {
            @include get-font('small');
            padding: $product-outofstock-popup-email-submit__padding;
            color: $product-outofstock-popup-email-submit__color;
        }

        &-consent {
            @include get-font('small');
            margin: $product-outofsstock-popup-consent__margin;

            &-link {
                @include get-font('small');
                text-decoration-color: rgba($black-two, 0.2);
            }
        }
    }
}

.back-in-stock__success,
.product-outofstock-disclaimer,
.product-promotion-boxes {
    &.hide {
        display: none;
    }
}

.back-in-stock__error {
    display: block;
    padding: $back-in-stock__error__padding;
    color: $back-in-stock__error__color;
}

.back-in-stock__success {
    display: none;
}

.promotion {
    &.hide {
        display: none;
    }
}

.product-promotion-boxes {
    @include flexbox();
    @include align-items(center);
    @include get-font('small');
    height: $product-promotion-boxes__height;
    padding: $product-promotion-boxes__padding;
    margin-top: $product-promotion-boxes__margin-top;
    background: $product-promotion-boxes__background;
    color: $product-promotion-boxes__color;

    .promotions {
        position: $product-promotion-boxes-promotions__position;
        margin-right: $product-promotion-boxes-promotions__margin-right;

        .promotion {
            min-width: $product-promotion-boxes-promotions-promotion__min-width;
            padding: $product-promotion-boxes-promotions-promotion__padding;

            &::after {
                border: $product-promotion-boxes-promotions-promotion-after__border;
                border-top-color: $product-promotion-boxes-promotions-promotion-after__border-top-color;
                border-bottom: 0;
                border-left: 0;
                margin-bottom: $product-promotion-boxes-promotions-promotion-after__margin-bottom;
            }
        }
    }
}

.product-outofstock {
    @include flexbox;
    @include flex-flow(row nowrap);
    @include align-items(center);
    padding: $product-outofstock__padding;
    margin-top: $product-outofstock__margin-top;
    background-color: $product-outofstock__background-color;

    &-circle {
        @include get-font('small-compact');
        display: table-cell;
        width: $product-outofstock-circle__width;
        min-width: $product-outofstock-circle__min-width;
        height: $product-outofstock-circle__height;
        border-radius: 50%;
        background: $product-outofstock-circle__background;
        color: $product-outofstock-circle__color;
        line-height: $product-outofstock-circle__line-height;
        text-align: center;
        vertical-align: middle;
    }

    &-text {
        @include get-font('mini-xsmall');
        display: inline-block;
        margin: $product-outofstock-text__margin;
        font-weight: $product-outofstock-text__font-weight;
    }
}

.promotion-box {
    padding: $promotion-box__padding;
    margin: $promotion-box__margin;
    
    &-circle {
        @include get-font('small-compact');
        display: table-cell;
        width: $promotion-box-circle__width;
        min-width: $promotion-box-circle__min-width;
        height: $promotion-box-circle__height;
        border-radius: 50%;
        background: $promotion-box-circle__background;
        color: $promotion-box-circle__color;
        line-height: $promotion-box-circle__line-height;
        text-align: center;
        vertical-align: middle;
    }

    &-text {
        @include get-font('mini-xsmall');
        display: inline-block;
        margin: $promotion-box-text__margin;
        font-weight: $promotion-box-text__font-weight;
    }

    &-tiles {
        @include flexbox();
        @include flex-wrap(nowrap);
        @include justify-content(space-between);
        @include align-items(stretch);
        margin: $promotion-box-tiles__margin;
        gap: $promotion-box-tiles__gap;
    }

    &-tile {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        border: $promotion-box-tile__border;
        padding: $promotion-box-tile__padding;
        text-align: center;
        width: 100%;
        max-width: $promotion-box-tile__max-width;
        cursor: pointer;

        &.active {
            background-color: $grey-background;
            border: $promotion-box-tile-active__border;
        }

        h4,
        h5,
        h6 {
            @include get-font('compact');
            line-height: $promotion-box-tile-text__line-height;
            white-space: nowrap;
        }

        h5,
        h6 {
            font-weight: 400;
            margin: $promotion-box-tile-text__margin;
        }

        h4 {
            font-weight: 700;
            margin: 0;
        }

        h6 {
            color: $dark-red;
        }
    }
}

.quantum {
    .promotion-box {
        padding: 0;

        &-text {
            margin: 0
        }
    }
}

.product-usps {
    @include flexbox;
    @include flex-flow(row nowrap);
    padding: $product-usps__padding;
    margin-top: $product-usps__margin-top;

    &-item {
        @include flexbox;
        @include align-items(center);

        &-img {
            max-width: $product-usps-item-img__max-width;
            max-height: $product-usps-item-img__max-height;
        }

        &:not(:first-child) {
            padding-left: $product-usps-item-not-first-child__padding-left;
        }
    }

    &-item > i {
        height: $product-usps-item-icon__height;
        font-size: $product-usps-item-icon__font-size;
        color: $orange;
    }

    &-text {
        @include get-font('micro');
        margin-left: $product-usps-text__margin-left;
        color: $greyish-brown;
    }
}

.product-info-section {
    @include flexbox;
    @include flex-flow(row nowrap);
    @include justify-content(space-between);
    margin-top: $product-info-section__margin-top;
}

.gamifiera-top-comment {
    @include flexbox;
    width: 100%;
    order: 11;

    .gmf-top-review {
        width: 100%;

        .gmf-comment-content {
            display: block;
        }
    }

    .gmf-comment-content-col {
        margin-right: 0;
        margin-bottom: 0;
    }

    .gmf-comment {
        padding-top: $product-info-section__padding-top--large;
        border-top: $product-info-section__border-top--large;
    }
}

.product-sku {
    @include get-font('mini-xsmall');
    color: $product-sku__color;
}

.product-short-description {
    @include get-font('xsmall-small');
    margin-top: $product-short-description__margin-top;
    color: $product-short-description__color;
    line-height: 1.4;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include get-font('small');
    }

    ul {
        padding-left: $product-short-description-ul__padding-left;
    }

    ul > li {
        @include get-font('mini-xsmall');
        position: relative;
        line-height: $product-short-description-ul-li__line-height;
        list-style: none;

        &::before {
            content: '\2022';
            position: absolute;
            margin-top: $product-short-description-ul-li-before__margin-top;
            margin-left: $product-short-description-ul-li-before__margin-left;
            font-size: $product-short-description-ul-li-before__font-size;
        }
    }
}

.brand {
    .brand-logo {
        max-width: $brand-logo__max-width;
    }
}

.product-tabs {
    padding: $product-tabs__padding;

    line-height: 1.4;

    p {
        line-height: 1.4;
    }

    .columns {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include get-font('small');
        }

        .greybox-long-description {
            padding: $base-padding $base-padding * 2;
            background: $very-light-pink-two;

            h2 {
                @include get-font('medium-big');
                line-height: 1.4;
            }

            p {
                line-height: 1.4;
            }
        }
    }

    .master-product-long-descriptions,
    .master-product-nutrition-and-ingredients {
        margin-top: $base-margin * 2;
    }

    .tabs-content {
        margin-top: $product-tabs-content__margin-top;

        iframe {
            width: 100%;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .nutrition-and-ingredients {
        overflow: auto;
    }

    .tabs-header {
        > .item {
            padding: $product-tabs-item__padding;

            &:first-child {
                padding-left: $product-tabs-first-item__padding-left;
            }
        }
    }

    .item {
        @include get-font('small');
        color: $product-tabs-item__color;

        &.active {
            color: $product-tabs-item-active__color;
        }

        p {
            @include get-font('small');
            color: $product-tabs-item__color;
            line-height: $p__line-height;

            &.preamble {
                @include get-font('compact');
                font-weight: $bolder;
                line-height: 1.4;
            }
        }

        h2 {
            @include get-font('medium-big');
            line-height: 1.4;
        }
    }

    table {
        margin-top: $table__margin-top;

        tr {
            border-top: $table-tr__border-top;
        }
    }

    .video-block-container {
        position: relative;
        overflow: hidden;
        padding-top: $product-video-block-container__pading-top;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: $product-video-block-full__width;
            height: $product-video-block-full__height;
            border: 0;
        }
    }
}

.hr-line-one,
.hr-line-two,
.hr-line-three,
.hr-line-four {
    width: 100%;
}

.hr-line-one {
    margin-top: $hr-line-one__margin-top;
}

.hr-line-two {
    margin-top: $hr-line-two__margin-top;
}

.hr-line-three {
    margin-top: $hr-line-three__margin-top;
}

.hr-line-four {
    margin-top: $hr-line-four__margin-top;
}

// Product siblings style

.product-siblings {
    @include flexbox();
    position: relative;
    right: 50%;
    left: 50%;
    overflow: auto;
    width: 100vw;
    padding: 0 ($base-padding * 1.2) ($base-padding * 2);
    margin: 0 -50vw;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    &-wrapper {
        position: relative;
    }

    &-color-wrapper {
        @include flexbox();
        @include align-items(center);
        margin: ($base-margin * 2) 0 $base-margin;
    }

    &-color-value {
        @include get-font('xsmall-small');
        padding-left: $base-padding * 0.5;
        font-weight: $bolder;
    }

    &-title {
        @include get-font('xsmall-small');
    }

    &-arrow-wrapper {
        position: absolute;
        z-index: 1;
        top: 50%;

        &.arrow-left {
            left: -15px;
        }

        &.arrow-right {
            right: -15px;
        }

        .icon-arrow-left-right {
            @include get-font('small');
            cursor: pointer;
            font-weight: 700;
        }
    }

    .product-tile {
        padding: 0;

        &-wrapper {
            @include flex-shrink(0);
            margin: 0 ($base-margin * 0.6);
            cursor: pointer;

            &.active {
                .product-tile-image {
                    border: ($border * 2) solid $black;
                }
            }

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }

        &-image__container {
            width: 10rem;
            height: 10rem;
        }

        &-image {
            width: 10rem;
            min-width: 10rem;
            height: 10rem;

            &:hover {
                border: ($border * 2) solid $black;
                cursor: pointer;
            }
        }

        &-num-of-variants-wrapper-mobile {
            display: none !important; // important is to override inline class
        }

        .wishlist-wrapper {
            display: none;
        }
    }

    // stylelint-disable selector-class-pattern
    .product-tile-description,
    .product-tile-CTA {
        display: none !important;
    }
}

@import '../screens/large/product/detail';
