// =============================================================================
//
//  Ribbon
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../themes/default/components/ribbon';

.promotions {
    position: absolute;

    .promotion {
        @include get-font('micro');
        display: block;
        min-width: $promotion__min-width;
        padding: $promotion__padding;
        background-color: $promotion__background-color;
        color: $promotion__color;
        font-weight: $promotion__font-weight;
        text-align: center;
        text-transform: uppercase;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 0;
            border: $promotion-after__border;
            border-top-color: $promotion-after__border-top-color;
            border-bottom: 0;
            border-left: 0;
            margin-bottom: $promotion-after__margin-bottom;
        }
    }
}

.promotions-wrapper {
    position: absolute;
    z-index: 99;
}

.promotions-right {
    .promotion {
        @include get-font('micro');
        display: block;
        min-width: $promotion__min-width;
        padding: $promotion__padding;
        background-color: $promotion__background-color;
        color: $promotion__color;
        font-weight: $promotion__font-weight;
        text-align: center;
        text-transform: uppercase;

        &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0.1rem;
            display: none;
            width: 0;
            height: 0;
            border: $promotion-after__border;
            border-top-color: $promotion-after__border-top-color;
            border-right: 0;
            border-bottom: 0;
            margin-bottom: $promotion-after__margin-bottom;
        }
    }
}


