// =============================================================================
//
//  General
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
//==============================================================================

@import './themes/default/general';

// -----------------------------------------------------------------------------
//  General:
// -----------------------------------------------------------------------------

html,
body {
    background-color: $html__background-color;
    color: $html__color;
    font-family: $montserrat;
    font-weight: $html__font-weight;
    line-height: 1;
}

* {
    box-sizing: border-box;
}

html::before {
    content: '';
    position: absolute;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background: $white;
    opacity: 0;
    transition: opacity 0.2s, z-index 0.2s step-end;
}


.page {
    padding-top: $page__padding-top;

    &.checkout {
        padding-top: 0;
    }
}

.container {
    width: 100%;
    margin: 0 auto;
}

.wrapper {
    position: relative;
    width: 100%;
    padding: $wrapper__padding;
    margin: 0 auto;

    &-header {
        padding: $wrapper-header__padding;
    }
}

.header-login-link {
    &.account-user-logged-in {
        padding-bottom: $account-user-logged-in__padding-bottom;
        border-bottom: $account-user-logged-in__border-bottom;
        color: $account-user-logged-in__color;

        svg {
            width: 1.8rem;

            .cls-1 {
                fill: $orange;
            }
        }
    }
}

img:not(.header-logo-image) {
    &.lazy-loading {
        filter: blur(2rem);
    }
    
    &.lazy-loaded {
        filter: blur(0);
        transition: 0.1s filter ease-in;
    }
}

// -----------------------------------------------------------------------------
//  Content Wrapper:
// -----------------------------------------------------------------------------

.content {
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    opacity: 1;
    transition: opacity 0.4s ease;
}

// -----------------------------------------------------------------------------
//  Screen Size Indicator:
// -----------------------------------------------------------------------------

.screen-size-indicator {
    position: absolute;
    top: -999em;
    left: -999em;

    &::before {
        content: 'small';
    }
}

@media screen and (min-width: $tablet-width) {
    .screen-size-indicator::before {
        content: 'medium';
    }
}

@media screen and (min-width: $desktop-width-x-large) {
    .screen-size-indicator::before {
        content: 'large';
    }
}

// -----------------------------------------------------------------------------
//  Smooth Overflow Scrolling:
// -----------------------------------------------------------------------------

.scrollable,
%scrollable {
    .no-touchevents & {
        overflow: auto;
    }

    .touchevents & {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll; // has to be scroll, not auto
    }

    &::-webkit-scrollbar {
        display: none;
    }
}

// -----------------------------------------------------------------------------
//  Product Image Wrapper:
// -----------------------------------------------------------------------------

.product__image-wrapper {
    position: relative;
    height: 0;
    padding-bottom: 100%;
}

// -----------------------------------------------------------------------------
//  Lazyload
// -----------------------------------------------------------------------------

.lazyload,
.lazyloading,
.lazyloaded {
    transition: 0.3s opacity ease-out;
}

.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
}

input[type=search] {
    appearance: none; /* rest */
}

input[type='search']::-webkit-search-cancel-button {
    appearance: none;
}

input[type='search']::-moz-search-cancel-button {
    appearance: none;
}

// -----------------------------------------------------------------------------
//  Lists
// -----------------------------------------------------------------------------

.no-styling-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

// -----------------------------------------------------------------------------
//  Fold
// -----------------------------------------------------------------------------

.unfoldable {
    display: none;
}

.unfold-link {
    display: block;
    margin: ($base-margin * 0.6) 0 ($base-margin * 0.2) 0;
}

.fold-link {
    display: none;
    margin: ($base-margin * 0.6) 0 ($base-margin * 0.2) 0;
}

@import 'screens/medium/general';
@import 'screens/large/general';
@import 'screens/medium-large/general';

/* stylelint-disable */
.zEWidget-launcher.zEWidget-launcher--active,
.zEWidget-webWidget.zEWidget-webWidget--active {
    z-index: 99 !important;
}

// -----------------------------------------------------------------------------
//  reCAPTCHA
// -----------------------------------------------------------------------------
.grecaptcha-badge {
    left: 100%;
    visibility: hidden;
}

.back-to-homepage {
    @include get-font('medium');
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    max-width: 30rem;
    height: 6rem;
    padding: 0;
    margin: 0 auto;
    color: $white;
    text-decoration: none;
    &:hover {
        color: $white;
    }
}

// -----------------------------------------------------------------------------
//  Style for the page which unregistered customers try to open
// -----------------------------------------------------------------------------

.unregistered-customers {
    &-page-wrapper {
        margin: ($base-margin * 5) 0;
        text-align: center;
    }
}

// -----------------------------------------------------------------------------
//  Page designer page
// -----------------------------------------------------------------------------

.widget-html-block {
    img {
        max-width: 100%;
    }
}
