// =============================================================================
//
//  Toast Messages
//
//  @version    0.0.1
//  @package    Bodystore
//
// =============================================================================

/**
 * Contains override styles for the toast messages specific to the website design.
 */

.toast-message {
    @include get-font('small');
}

/* stylelint-disable selector-max-id */
#toast-container {
    & > .toast.toast-error { // Important to keep the current selector.
        padding: 30px 40px;
        border-radius: 0;
        background-color: $orange-dash;
        background-image: none; // Hides icon on the left side.
        box-shadow: none;
        opacity: 0.95;
        text-align: justify;
    }

    &.toast-top-center > .toast,
    &.toast-bottom-center > .toast {
        width: auto;
        max-width: 700px;
        margin-bottom: 20px;

        @media screen and (max-width: $tablet-width) {
            max-width: 320px;
        }
    }

    .icon-close {
        @include get-font('small');
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}
