// =============================================================================
//
//  Typography Base
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
//==============================================================================

@import '../themes/default/typography/base';

// -----------------------------------------------------------------------------
//  HTML and Body:
// -----------------------------------------------------------------------------

html {
    font-size: 10px;
    text-size-adjust: 100%;
}

body {
    position: relative;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1;
    overflow-x: hidden;
    transition: color 0.4s ease;
}

// -----------------------------------------------------------------------------
//  Links:
// -----------------------------------------------------------------------------

/* stylelint-disable property-no-vendor-prefix */

a {
    @include get-font('xsmall');
    color: $a__color;
    cursor: pointer;
    text-decoration-color: $a__text-decoration-color;
    text-underline-position: under;
    -webkit-transition: color 0.3s ease, text-decoration-color 0.3s ease;
    -moz-transition: color 0.3s ease, text-decoration-color 0.3s ease;
    -o-transition: color 0.3s ease, text-decoration-color 0.3s ease;
    transition: color 0.3s ease, text-decoration-color 0.3s ease;

    &:hover {
        color: $a-hover__color;
        text-decoration-color: $a-hover__text-decoration-color;
    }

    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

// -----------------------------------------------------------------------------
//  Headings:
// -----------------------------------------------------------------------------

h1 {
    @include get-font('large');
    margin: 0;
    font-weight: $h1__font-weight;
    letter-spacing: $h1__letter-spacing;
    line-height: $h1__line-height;
}

h2 {
    @include get-font('large');
    margin: 0;
    font-weight: $h2__font-weight;
    letter-spacing: $h2__letter-spacing;
    line-height: $h2__line-height;
}

.medium-title {
    @include get-font('medium');
    flex: 100%;
    font-weight: $bolder;
    text-align: center;
}

h3 {
    @include get-font('compact');
    margin: 0;
    font-weight: $h3__font-weight;
    letter-spacing: $h3__letter-spacing;
    line-height: $h3__line-height;
}

// -----------------------------------------------------------------------------
//  Image:
// -----------------------------------------------------------------------------

picture {
    display: block;
    line-height: 0;
}

img {
    border: 0;
}

// -----------------------------------------------------------------------------
//  Button:
// -----------------------------------------------------------------------------

button,
.button {
    @include get-font('small');
    max-width: 100%;
    padding: $button__padding;
    border: none;
    background-color: $button__background-color;
    color: $button__color;
    cursor: pointer;
    font-weight: $bolder;
    transition: $button__transition;

    &.button--yellow {
        background-color: $button-yellow__background-color;
        color: $button-yellow__color;

        &:hover {
            background-color: $button-yellow-hover__background-color;
            color: $button-yellow__color;
        }
    }

    &.button--red--darkred {
        background-color: $red;
        color: $white;

        &:hover {
            background-color: $dark-red;
            color: $white;
        }
    }

    &:hover {
        background-color: $button-hover__background-color;
        color: $button__color;
        cursor: pointer;
    }

    &:active,
    &:focus,
    &.button--active {
        outline: 0;
    }
}

// -----------------------------------------------------------------------------
//  Text:
// -----------------------------------------------------------------------------

p {
    @include get-font('small');
    line-height: $p__line-height;
}

// -----------------------------------------------------------------------------
//  Table:
// -----------------------------------------------------------------------------

table {
    width: 100%;
    border-collapse: collapse;

    thead {
        background-color: $mine-shaft;

        th {
            @include get-font('small');
            padding: $base-padding;
            color: $white;
            font-weight: $normal-weight;
            text-align: left;
            word-break: break-word;
        }
    }

    tbody {
        background-color: $very-light-pink-two;

        tr {
            border-top: $border solid $very-light-pink-two;
        }

        td {
            @include get-font('small');
            padding: $base-padding;
            color: $greyish-brown;
            text-align: left;
            word-break: break-word;
        }
    }
}

// -----------------------------------------------------------------------------
//  Other Elements:
// -----------------------------------------------------------------------------

// Bold:

b,
strong {
    font-weight: $b__font-weight;
}

// HR

hr {
    margin: 0;
}

@import '../screens/medium/typography/base';
@import '../screens/large/typography/base';
