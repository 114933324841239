// =============================================================================
//
//  Dropdown
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../settings';

@import '../themes/default/components/dropdown';

.dropdown__container {
    position: relative;
    height: $dropdown-container__height;
}

.dropdown {
    position: relative;
    border: $dropdown__border;
    background-color: $dropdown__background-color;

    // -------------------------------------------------------------------------
    //  Dropdown Header:
    // -------------------------------------------------------------------------

    &-header {
        background-color: $white;
        cursor: pointer;
    }

    &-header > .title {
        @include get-font('xsmall');
        position: relative;
        display: block;
        padding: $dropdown-header-title__padding;
        margin: $dropdown-header-title__margin;
        color: $dropdown-header-title__color;
        line-height: $dropdown-header-title__line-height;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        &::after {
            content: '\70';
            position: absolute;
            right: 0;
            padding-left: $dropdown-header-title-after__padding-left;
            border-left: $dropdown-header-title-after__border-left;
            font-family: 'bodystore-icons' !important;
            font-size: $dropdown-header-title-after__font-size;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-style: normal !important;
            font-variant: normal !important;
            font-weight: normal !important;
            line-height: $dropdown-header-title-after__line-height;
            speak: none;
            text-transform: none !important;
        }
    }

    &.single-variation {
        .dropdown-header {
            cursor: default;
            
            > .title {
                &::after {
                    display: none;
                }
            }
        }
    }

    // -------------------------------------------------------------------------
    //  Dropdown Content:
    // -------------------------------------------------------------------------

    &-content {
        display: none;
    }

    &-content > .item {
        padding-right: $dropdown-content-item-active__padding-right;
        padding-left: $dropdown-content-item-active__padding-left;
        margin: 0;
        cursor: pointer;
    }

    .dropdown-header > .title {
        &::after {
            border-left: $dropdown-header-title-after-not-active__border-left;
        }
    }

    // -------------------------------------------------------------------------
    //  Dropdown Active:
    // -------------------------------------------------------------------------

    &.active {
        z-index: 100;
        box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.2);

        .dropdown-header > .title {
            border-bottom: $dropdown-header-title-active__border-bottom;

            &::after {
                border-left: $dropdown-header-title-after__border-left;
            }
        }

        .dropdown-content {
            position: relative;
            z-index: 1;
            display: block;
            padding: $dropdown-content-active__padding;
            background-color: $dropdown-content-active__background-color;
        }

        .dropdown-content > .item {
            padding-right: $dropdown-content-item-active__padding-right;
            padding-left: $dropdown-content-item-active__padding-left;
            margin: 0;
        }
    }
}

