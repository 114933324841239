// =============================================================================
//
//  Inputs
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

@import '../themes/default/components/inputs';

// -----------------------------------------------------------------------------
//  Text-inputs and Textareas
// -----------------------------------------------------------------------------

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='time'],
input[type='week'],
textarea,
select {
    @include get-font('small');

    width: 100%;
    box-sizing: border-box;
    padding: $input__padding;
    border: $border solid $black;
    border-radius: 0;
    appearance: none;
    background-color: $transparent;
    box-shadow: none;
    color: $black;
    transition: border-color 0.2s ease, opacity 0.2s ease, box-shadow 0.2s ease;

    &::placeholder {
        color: $brown-grey;
    }

    &::-ms-expand {
        display: none;
    }

    &:active,
    &:focus,
    &.input--active {
        box-shadow: none;
        outline: 0;

        &.is-invalid,
        .form-group.is-invalid & {
            border-color: $input-is-invalid__border-color;
        }
    }

    &:disabled,
    [disabled='disabled'] {
        border-bottom-color: $input-disabled__border-bottom-color;
        opacity: 0.5;
    }

    &.is-invalid,
    .form-group.is-invalid & {
        border-color: $input-is-invalid__border-color;

        + .error {
            display: block;
        }
    }

    &.is-valid,
    .form-group.is-valid & {
        + .is-valid {
            display: block;
        }
    }
}

// -----------------------------------------------------------------------------
//  Select
// -----------------------------------------------------------------------------

.select-dropdown {
    cursor: pointer;

    + .select-dropdown-icon {
        position: absolute;
        top: $select-dropdown-icon__top;
        right: 1.8rem;
        font-size: $select-dropdown-icon__font-size;
        pointer-events: none;
    }
}

// -----------------------------------------------------------------------------
//  Checkbox
// -----------------------------------------------------------------------------

input[type='checkbox'] {
    display: none;

    + .form-control-label {
        position: relative;
        display: table;
        cursor: pointer;

        .checkbox-icon {
            display: none;
        }

        &::before {
            content: '';
            position: absolute;
            width: $input-checkbox-before__width;
            height: $input-checkbox-before__height;
            box-sizing: border-box;
            border: $brown-grey solid $border;
            background-color: $transparent;
            transition: background-color 0.2s ease;
        }
    }

    &:checked + .form-control-label {
        .checkbox-icon {
            position: absolute;
            top: $input-checkbox-icon__top;
            left: $input-checkbox-icon__left;
            display: block;
            color: $input-checkbox-icon__color;
            font-size: $input-checkbox-icon__font-size;
        }

        &::before {
            border: none;
            background-color: $orange-dash;
            opacity: 1;
        }
    }

    &:disabled + .form-control-label {
        cursor: initial;
        opacity: $input-checkbox-disabled-before__opacity;
        pointer-events: none;

        &::before {
            opacity: $input-checkbox-disabled-before__opacity;
        }
    }
}

// -----------------------------------------------------------------------------
//  Radio
// -----------------------------------------------------------------------------

input[type='radio'] {
    display: none;

    + .form-control-label {
        position: relative;
        display: block;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: $input-radio-before__width;
            height: $input-radio-before__height;
            box-sizing: border-box;
            border: $input-radio-before__border;
            border-radius: 100%;
            background-color: $input-radio-before__background-color;
            color: $input-radio-before__color;
            line-height: $input-radio-before__line-height;
            text-align: center;
            vertical-align: middle;
        }
    }

    &:checked + .form-control-label {
        &::before {
            border: $input-radio-checked-before__border;
            background-color: $input-radio-checked-before__background-color;
            box-shadow: $input-radio-checked-before__box-shadow;
        }
    }

    &:disabled + .form-control-label {
        cursor: initial;
        opacity: $input-checkbox-disabled-before__opacity;
        pointer-events: none;

        &::before {
            opacity: $input-radio-disabled-before__opacity;
        }
    }
}

// -----------------------------------------------------------------------------
//  Quantity Form
// -----------------------------------------------------------------------------

.quantity-form {
    @include flexbox;
    @include flex-flow(row nowrap);
    @include justify-content(center);

    .quantity {
        @include get-font('small');
        width: $quantity__width;
        height: $quantity__height;
        padding: $quantity__padding;
        border: $quantity__border;
        margin: $quantity__margin;
        color: $quantity__color;
        font-weight: $quantity__font-weight;
        text-align: center;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            margin: 0;
            appearance: none;
        }
    }

    .quantity-icon {
        padding: $quantity-icon__padding;
        color: $quantity-icon__color;
        cursor: pointer;
        font-size: $quantity-icon__font-size;

        &::before {
            display: inline-block;
            padding: $quantity-icon-before__padding;
            border-radius: 100%;
            background-color: $quantity-icon-before__background-color;
        }

        &.icon--disabled {
            opacity: $quantity-icon-icon-disabled__opacity;
        }

        &:hover {
            &::before {
                background-color: $quantity-icon-hover-before;
            }
        }
    }
}

/* stylelint-disable */
input[type='number'] {
    -moz-appearance: textfield;
}

