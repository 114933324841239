// =============================================================================
//
//  Collapsible Item Variables
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Willy Larsson <willy@improove.se>
//
// =============================================================================

$collapsible__border-top: $very-light-pink solid $border;
$collapsible__border-bottom: $very-light-pink solid $border;
$collapsible__margin: ($base-margin * -0.1) 0;

$collapsible-header-title__padding: ($base-padding * 1.5) ($base-padding * 2) ($base-padding * 1.5) 0;
$collapsible-header-title__color: $black;

$collapsible-header-title-after__font-size: 1.6rem;
$collapsible-header-title-after__line-height: 1.2;

$collapsible-content-active__padding-bottom: $base-padding * 1.5;
$collapsible-content__line-height: 1.5;
