// =============================================================================
//
//  404 Page
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Marko Bojovic <marko@improove.se>
//
//==============================================================================

@import '../../themes/default/components/pages/404page';

.error-page {
    &-wrapper {
        padding: $error-page-wrapper__padding;
        margin: $error-page-wrapper-top__margin;
        text-align: center;

        .page-text-content {
            @include get-font('small');
            margin-bottom: $error-page-content__margin-bottom;
        }

        .page-title {
            @include get-font('large');

            &.csrf-message {
                margin-bottom: $base-margin * 3;
            }
        }
    }

    &-img {
        margin-top: $error-page-page-img__margin-top;
        margin-bottom: $error-page-page-img__margin-bottom;
    }
}

.error-product-recommendations {
    margin-bottom: $error-product-recommendations__margin-bottom;

    .product-recommendation-grid {
        > .product:first-child {
            border-top: $error-product-recommendations-calloutmsg__border-top;
        }
    }

    .product-recommendation-calloutmsg {
        padding-top: $error-product-recommendations-calloutmsg__padding-top;
        border-top: $error-product-recommendations-calloutmsg__border-top;
    }
}

@import '../../screens/medium-large/components/pages/404page';
