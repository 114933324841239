// =============================================================================
//
//  Collapsible Item Desktop
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Bojan Brkic <bojan.brkic@impactcommerce.se>
//
// =============================================================================

@media screen and (min-width: $desktop-width) {
    .account-faq {
        h2 {
            margin-bottom: 2.5rem;
        }

        .title {
            @include get-font('compact');
            font-weight: 500;
            padding: 2.5rem 5.5rem 2.5rem 0;

            &::before {
                right: 1.5rem;
            }
        }

        .collapsible-content {
            @include get-font('small');

            p,
            a {
                @include get-font('small');
            }
        }

        .collapsible.active {
            .collapsible-content {
                padding-bottom: 2.5rem;
            }
        }
    }
}
