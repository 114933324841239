// =============================================================================
//
//  Static Page
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@import '../../themes/default/components/content/staticPage';

.static-page {
    margin: $static-page__margin;

    &-menu-container,
    &-body-container {
        width: 100%;
    }

    &-body-container {
        margin-top: $static-page-body-container__margin-top;
    }

    &-menu-content {
        padding: 0;
    }

    &-menu-content-item {
        display: block;
        padding: $static-page-menu-content-item__padding;
        text-decoration: none;
    }
}

